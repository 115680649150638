import React, { useContext } from 'react';

import { DoorsContext } from '../../../../context/DoorsContext';

export default function DoppiaAntaBattenteCutListTable() {
    const { doorOrder } = useContext(DoorsContext);

    return (
        doorOrder.doors.map((door, i) => {
            return <div key={i} className='doors-table mt-32'>
                <p>{door.qty} Porte Anta Doppia Battente - {door.lengthWallHole}cm x {door.heightWallHole}cm</p>
                <div className='header font-bold'>
                    <p>N° Pezzi</p>
                    <p>Articolo</p>
                    <p>Larghezza</p>
                    <p>Altezza</p>
                </div>
                <div className='table-content'>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Pannelli</p>
                        <p>{doorOrder.antaLength - 4.7}cm</p> {/* da larghezza imbotte anta piccola togliere 2,7cm. */}
                        <p>{door.heightWallHole - 6}cm</p> {/* per le altezze togliere 3,1cm dalla misura del battente */}
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Pannelli</p>
                        <p>{door.lengthWallHole - doorOrder.antaLength - 7.5 - 4.7}cm</p> {/* da larghezza imbotte anta grande togliere 2,7cm. */}
                        <p>{door.heightWallHole - 6}cm</p> {/* per le altezze togliere 3,1cm dalla misura del battente */}
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Profili Imbotte</p>
                        <p>{door.lengthWallHole - 2}cm</p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty * 2}</p>
                        <p>Profili Imbotte</p>
                        <p></p>
                        <p>{door.heightWallHole - 1}cm</p> 
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Rinforzi per Imbotte</p>
                        <p>{door.lengthWallHole - 24.2}cm</p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty * 3}</p>
                        <p>Rinforzi per Telaio</p>
                        <p></p>
                        <p>{door.heightWallHole - 12.5}cm</p>
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Battenti</p>
                        <p>{doorOrder.antaLength}cm</p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Battenti</p>
                        <p>{door.lengthWallHole - doorOrder.antaLength - 7.5}cm</p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty * 3}</p>
                        <p>Battenti</p>
                        <p></p>
                        <p>{door.heightWallHole - 2.9}cm</p>
                    </div>
                    <div className='row'>
                        <p>{door.qty * 3}</p>
                        <p>Doppio Battente</p>
                        <p></p>
                        <p>{door.heightWallHole - 0.2}cm</p>
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Profili U/C</p>
                        <p>{door.lengthWallHole - 2}cm</p>
                        <p></p>
                    </div>
                    <div className='row'>
                        <p>{door.qty}</p>
                        <p>Profili U/C</p>
                        <p></p>
                        <p>{door.heightWallHole - 2}cm</p>
                    </div>
                </div>
            </div>
        })
    );
}